import React, {useEffect, useRef, useState, useCallback} from 'react';
import SlideToggle from 'react-slide-toggle';
import Block from '../../adapters/helpers/Block';
import { stripHtml } from '../../adapters/helpers/Utils';
import Component from '../../adapters/helpers/entity/Component'
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import { ProductSubNavConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import throttle from 'lodash/throttle';
import { updateCurrentProductIndex } from '../../adapters/helpers/Hooks';
import {scrollIntoView} from '../../adapters/helpers/Scroll';
import { sanitize } from 'isomorphic-dompurify';

export default function ProductSubNav(props) {
    const {extraAttributes} = props;

    const block = new Block(props);
    const product = new Component(extraAttributes.entity);
    const className = 'ob-product-sub-nav is-sticky product-sub-nav-scroll';
    const buyNowLabel = product?.props?.productOverview?.fields?.buyNowLabel?.fields?.text;
    const buyNowDeactivated = product?.props?.productOverview?.fields?.deactivateBuyNowButton || false;
    const comingSoonText = product?.props?.productOverview?.fields?.comingSoonLabel?.fields?.text || '';
    const comingSoonLink = product?.props?.productOverview?.fields?.comingSoonLink || '';
    const prePurchaseBlock = product?.props?.blocks && product?.props?.blocks?.[0]?.fields?.blocks?.[1]?.fields?.blocks?.some((ele)=>ele?.fields?.anchorId == ProductSubNavConstants.prepurchase)
    const isEnableCommingSoon = product?.props?.productOverview?.fields?.enableComingSoonLabel;
    const poweredByLabel = product?.props?.productOverview?.fields?.poweredBy;
    const productVariants = product?.props?.productOverview?.fields?.productVariants;

    const productSubNavRef = useRef(null);
    const [showNav, setShowNav] = useState(true);
    const [scrollPos, setScrollPos] = useState(0);
    const [globalState] = updateCurrentProductIndex();

    const updateNavState = () => {
        const tempPos = window.pageYOffset;
        const isVisible = scrollPos > tempPos;
        setShowNav(isVisible);
        setScrollPos(tempPos);
    };

    const updateStyles = updateEvent => {
        const header = document.querySelector('.zone-header');
        const productSubNav = productSubNavRef.current;
        const firstBlock = document.querySelector('.ob-product-sub-nav.is-sticky + div');
        const layout = document.querySelector('.layout');
        const breadcrumbs = document.querySelector('.breadcrumb-main');
        if (!productSubNav || !firstBlock || !header || !layout || !breadcrumbs) {
            return;
        }
        if (updateEvent === ProductSubNavConstants.scroll) {
            header.classList.add(`visible-${showNav}`);
            header.classList.remove(`visible-${!showNav}`);
            breadcrumbs.classList.add(`visible-${!showNav}`); //1
            breadcrumbs.classList.remove(`visible-${showNav}`);
            if (breadcrumbs.classList.contains('visible-false')) {
                breadcrumbs.style.top =  `${header.offsetHeight+4}px`; //2
                breadcrumbs.style.position =  'fixed'; //2
            } else if (breadcrumbs.classList.contains('visible-true')) {
                breadcrumbs.style.top =  '0px'; //2
                breadcrumbs.style.position =  'fixed'; //2
            }

            productSubNav.classList.add(`is-top-${!showNav}`);
            productSubNav.classList.remove(`is-top-${showNav}`);

            // This prevents a page glitch and a huge white space at the top of the page
            header.style.top = showNav ? '0' : `-${header.offsetHeight}px`;
            productSubNav.style.top = showNav ? `${header.offsetHeight+breadcrumbs.offsetHeight}px` : `${breadcrumbs.offsetHeight}px`;
            firstBlock.style.paddingTop = scrollPos <= (productSubNav.offsetHeight) && showNav ? `${productSubNav.offsetHeight+breadcrumbs.offsetHeight+4}px` : '0';
            layout.style.paddingTop = scrollPos <= (productSubNav.offsetHeight) && showNav ? `${header.offsetHeight}px` : '0';
        } else if (updateEvent === ProductSubNavConstants.anchorClick || updateEvent === ProductSubNavConstants.initialLoad) {
            if (header.classList.contains('visible-true')) {
                layout.style.paddingTop = `${header.offsetHeight}px`;
            }
            firstBlock.style.paddingTop = `${productSubNav.offsetHeight+breadcrumbs.offsetHeight}px`;
            if(updateEvent === ProductSubNavConstants.initialLoad) {
                // SetTimeout makes sure the main menu has time to load
                window.setTimeout(() => {
                    layout.style.paddingTop = scrollPos <= (productSubNav.offsetHeight) && showNav ? `${header.offsetHeight}px` : '0';
                    productSubNav.style.top = showNav ? `${header.offsetHeight+breadcrumbs.offsetHeight}px` : '0';
                    firstBlock.classList.add('ob-padding-transition');
                }, 100);
            }
        }
    };

    useEffect(() => {
        let headerEle = document.getElementsByClassName('zone-header');
        if(headerEle) {
            headerEle[0].style.position='relative';
        }        
    },[]);

    const productNavScroll = () => {
        const tempPos = window.pageYOffset;
        const isVisible = scrollPos > tempPos;
        setShowNav(isVisible);
        setScrollPos(tempPos);        
    };

    let startProductBarPos=-1;
    
     function findPosY(obj) {
       var curtop = 0;
       if (typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
         while (obj.offsetParent) {
           curtop += obj.offsetTop;
           obj = obj.offsetParent;
         }
         curtop += obj.offsetTop;
       }
       else if (obj.y)
         curtop += obj.y;
       return curtop;
     }
 
     const [y, setY] = useState(0);
     
     function isInViewport(element) {
         const rect = element.getBoundingClientRect();
         return (
             rect.top >= 0 &&
             rect.left >= 0 &&
             rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
             rect.right <= (window.innerWidth || document.documentElement.clientWidth)
         );
     }

     const handleNavigation = useCallback(
        e => {
          const window = e.currentTarget;
          let breadcrumbEle = document.getElementById('breadcrumb-main');
          let highlightsEle = document.getElementById('highlights');
         
          let breadcrumbStatus = isInViewport(breadcrumbEle);

          if (y > window.scrollY) {
            let eleDomUp = document.getElementById('ob-product-subnav-scroll');
            if(startProductBarPos<0)startProductBarPos=findPosY(eleDomUp); 

            if(pageYOffset>startProductBarPos) {
                eleDomUp.style.position='fixed';
                breadcrumbStatus ? eleDomUp.style.position='inherit' : eleDomUp.style.position='fixed';
                eleDomUp.style.top=0;
                eleDomUp.style.zIndex=99;            
              }else{ 
                eleDomUp.style.top='auto';   
                eleDomUp.style.position='relative';   
                }
            } else if (y < window.scrollY) {
                let eleDomDown = document.getElementById('ob-product-subnav-scroll');
                if(startProductBarPos<0)startProductBarPos=findPosY(eleDomDown);        
                if(pageYOffset>startProductBarPos) {
                    breadcrumbStatus ? eleDomDown.style.position='inherit' : eleDomDown.style.position='fixed';
                    eleDomDown.style.top=0;
                    eleDomDown.style.zIndex=99;                    
                } else {
                    eleDomDown.style.position='relative'; 
                }
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);    
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

    const scrollTo = event => {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        const anchorId = currentTarget.dataset.anchorId;
        const element = document.querySelector(anchorId);
        if (!element) {
            return;
        }
        let headerOffset = 150;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
    };

    const renderLink = (product, link, index) => {
        return (linkNeedToDisplay(product, link)) ?
            (<li key={index}>
                <a onClick={scrollTo}
                    data-anchor-id={link.url} href={link.url}
                    className={link?.linkClassName ? link.linkClassName : 'event_internal_link'}
                    data-action-detail={stripHtml(link.title)}>
                    <span dangerouslySetInnerHTML={{__html: sanitize(link.title)}}></span>
                </a>
            </li>) : '';
    };

    const linkNeedToDisplay = (product, link) => {
        let reviewsBlock;
        switch (link.url) {
            case '#highlights':
                return (typeof product.productHighlights !== ProductSubNavConstants.undefined);
            case '#ob-highlights-ctn':
                return (typeof product.productHighlights !== ProductSubNavConstants.undefined);
            case '#features' :
                return (typeof product.featuresTab !== ProductSubNavConstants.undefined);
            case '#in-the-box' :
                return (typeof product.inTheBox !== ProductSubNavConstants.undefined);
            case '#gallery' :
                return (typeof product.gallery !== ProductSubNavConstants.undefined);
            case '#reviews' :
                // Object provides is featuredQuote for reviews
                reviewsBlock = productSubNavRef?.current?.parentNode.querySelector('#reviews');
                return (typeof product.featuredQuote !== ProductSubNavConstants.undefined || reviewsBlock !== ProductSubNavConstants.undefined || reviewsBlock !== null);
            case '#prepurchase' :
                return prePurchaseBlock == true;
            default :
                return false;
        }
    };

    const renderShopNowCTA = () => {
        const comingSoonBtnLink = (productVariants && productVariants[globalState.currentProductId]?.fields?.comingSoonLink) || comingSoonLink;
        return <Button
            tag={'a'}
            disabled={comingSoonBtnLink === ''}
            href={comingSoonBtnLink}
            size={ProductSubNavConstants.medium}
            target={ProductSubNavConstants.blank}
        >
            {comingSoonText}
        </Button>
    }

    const renderFindRetailerCTA = () => {
        const btnClassName = isEnableCommingSoon ? 'ob-product-sub-nav__find-a-retailer-secondary-btn-style' : '';
        return buyNowLabel && !buyNowDeactivated && <Button
                tag={'button'}
                textTheme={isEnableCommingSoon}
                borderTheme={!isEnableCommingSoon}
                size={ProductSubNavConstants.medium}
                className={`${btnClassName} event_buy_now`}
                dataActionDetail={stripHtml(product.props.productOverview.fields.title)}
                onClick={event => props.onClickCallback(event)}
                sku={extraAttributes?.entity?.productOverview?.fields?.productVariants && extraAttributes?.entity?.productOverview?.fields?.productVariants[globalState?.currentProductId]?.fields?.sku}
            >
                {buyNowLabel}
            </Button>
    }

    return (
        <div className={className} ref={productSubNavRef} id='ob-product-subnav-scroll'>
            {/* START MOBILE LAYOUT */}
            <div className={'ob-product-sub-nav__container ob-product-sub-nav__container--mobile'}>
                <SlideToggle collapsed duration={500}>
                    {({ onToggle, setCollapsibleElement, range, toggleState }) => {
                        const styles = range ? { opacity: Math.max(0, range) } : { opacity: 1 };
                        return (
                            <div className={'ob-product-sub-nav__top'}>
                                <h2 className={'ob-product-sub-nav__title'}>
                                    <button className={'event_menu_click'}
                                            aria-expanded={toggleState === ProductSubNavConstants.Collapsed || toggleState === ProductSubNavConstants.Collapsing ? false : true}
                                            data-action-detail={AnalyticsConstants.dropdownMenu}
                                            aria-hidden='false'
                                            onClick={() => {
                                        onToggle();
                                        updateNavState();
                                    }}>
                                        <span dangerouslySetInnerHTML={{__html: sanitize(product.props.productOverview.fields.title)}}></span>
                                        {toggleState === ProductSubNavConstants.Collapsed || toggleState === ProductSubNavConstants.Collapsing ? (
                                            <Icon name={ProductSubNavConstants.chevronDown} color="#3D3D41" />
                                            ) : (
                                            <Icon name={ProductSubNavConstants.chevronTop} color="#3D3D41" />
                                            )}
                                    </button>
                                </h2>
                                <div className={'ob-product-sub-nav__ob-btn-section'}>
                                    {isEnableCommingSoon && <div>
                                        {renderShopNowCTA()}
                                        {poweredByLabel && <p className="ob-product-sub-nav__powered-by-label">{poweredByLabel}</p>}
                                    </div>}
                                    {renderFindRetailerCTA()}
                                </div>

                                <ul ref={setCollapsibleElement} className={'ob-page-navigation__list'} style={styles}>
                                    {block.getFieldValue(ProductSubNavConstants.links).map((link, index) => {
                                        return renderLink(product.props, link.fields, index)
                                    })}
                                </ul>
                            </div>
                        )
                    }}
                </SlideToggle>
            </div>
            {/* END MOBILE LAYOUT */}

            {/* START DESKTOP LAYOUT */}
            <div className={'ob-product-sub-nav__container ob-product-sub-nav__container--desktop'}>
                <div className={'ob-product-sub-nav__top'}>
                    <Heading className={'ob-product-sub-nav__title'} tag={ProductSubNavConstants.h2}>{product.props.productOverview.fields.title}</Heading>
                    <ul className={'ob-page-navigation__list'}>
                        {block.getFieldValue(ProductSubNavConstants.links).map((link, index) => {
                            return renderLink(product.props, link.fields, index)
                        })}
                    </ul>
                </div>
                
                <div className={'ob-product-sub-nav__ob-btn-section'}>
                    {isEnableCommingSoon && <div>
                        {renderShopNowCTA()}
                        {poweredByLabel && <p className="ob-product-sub-nav__powered-by-label">{poweredByLabel}</p>}
                    </div>}
                    {renderFindRetailerCTA()}
                </div>
            </div>
            {/* END DESKTOP LAYOUT */}
        </div>
    );
}

ProductSubNav.propTypes = {
    extraAttributes: PropTypes.any,
    onClickCallback: PropTypes.func
};
